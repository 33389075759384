.App {
  margin: 0 30px;
}

.center {
  margin: auto;
}

.row {
  display: flex;
  justify-content: space-between;
}