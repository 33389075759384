.number {
    padding-top: 40px;
    border: 1px black solid;
    width: 100px;
    height: 60px;
    text-align: center;
}

button.number {
    padding-top: 10px;
    height: 100px;
}

.number:active {
    background-color: #aaa;
}


