.input-display {
    font-size: 27px;
    text-align: center;
    width: 95%;
    height: 35px;
    border: black 1px solid;
    margin-bottom: 10px;
    padding: 5px;
}

.correct-answer {
    color: red;
    font-size: 27px;
    height: 27px;
    text-align: center;
    font-weight: bold;
    margin-bottom: 20px;
}